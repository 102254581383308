import React from "react";
import Navigation from '../../components/navigation'
import LargeImage from '../../components/largeImage'
import useBasic from './hooks'
import CtaButton from '../../components/CtaButton'

import "./basic.scss";

const Content = () => {
  const {
    name,
    email,
    company,
    username,
    password,
    passwordConfirmation,
    errors,
  } = useBasic()
  
  const disableNavigation = Object.values(errors).some(x => x)

  return <div className="section">
    <div className="container">
      <div className="columns is-main-columns is-desktop">
        <div className="column"><LargeImage /></div>
        <div className="column">
          <h2 className="main-title">Opret din konto</h2>
          <p>Start dine 30 dages gratis backup (Kreditkort skal ikke indtastes)<br />Har du allerede en konto? <a href="https://www.dansavebackup.dk">Log in her</a></p>
          <form>
            <div className="field">
              <label className="label">Navn</label>
              <div className="control">
                <input className="input" value={name.value} onChange={name.onChange} onBlur={name.onBlur} type="text" />
              </div>
              { errors.name && <p className="help is-danger">{ errors.name }</p> }
            </div>
            <div className="field">
              <label className="label">E-mail</label>
              <div className="control">
                <input className="input" value={email.value} onChange={email.onChange} onBlur={email.onBlur} type="email" />
              </div>
              { errors.email && <p className="help is-danger">{ errors.email }</p> }
            </div>
            {/* <div className="field">
              <label className="label">Firma</label>
              <div className="control">
              <input className="input" value={company.value} onChange={company.onChange} onBlur={company.onBlur} type="text" />
              </div>
              { errors.company && <p className="help is-danger">{ errors.company }</p> }
            </div> */}
            {/* <div className="field">
              <label className="label">Brugernavn</label>
              <div className="control">
              <input className="input" value={username.value} onChange={username.onChange} onBlur={username.onBlur} type="text" />
              </div>
            </div>
            { errors.username && <p className="help is-danger">{ errors.username }</p> } */}
            <div className="columns is-desktop is-password-columns">
              <div className="column">
                <div className="field">
                  <label className="label">Adgangskode</label>
                  
                  <div className="control">
                  <input className="input" value={password.value} onChange={password.onChange} onBlur={password.onBlur} type="password" />
                  </div>
                </div>
              </div>
              {/* <div className="column">
                <div className="field">
                  <label className="label">Bekræft adgangskode</label>
                  <div className="control">
                  <input className="input" value={passwordConfirmation.value || ""} onChange={passwordConfirmation.onChange} type="password" />
                  </div>
                </div>
              </div> */}
            </div>
            <small>Skal være minimum 6 karakterer, indeholde små og store bogstaver samt tal og specialtegn</small>
            { (errors.password && <p className="help is-danger">{ errors.password }</p>) || (errors.passwordConfirmation && <p className="help is-danger">{ errors.passwordConfirmation }</p>)}
            <div className="columns">
              <div className="column is-half is-offset-half">
                <CtaButton disabled={disableNavigation} to="/ydelser">Næste</CtaButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}

export default () => <>
  <Navigation activeFromZero={0} />
  <Content />
</>