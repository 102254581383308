import { useReducer } from 'react'
import useDataValidation from './useDataValidation'
import set from 'lodash/set'
import { sizePrice, modules } from '../../../data'
import {calculatePrice} from '../../../data'


function clean(obj) {
  for (var propName in obj) { 
    if (obj[propName] === "") {
      delete obj[propName];
    } else if(typeof obj[propName] === 'object'){
      obj[propName] = clean(obj[propName])
    }
  }
  return obj
}
const defaultLocation = Object.keys(sizePrice)[0]
const defaultSize = Object.keys(sizePrice[defaultLocation])[0]
const pureInfo = {
  data: {
    basic: {},
    size: {
      size: defaultSize,
      location: defaultLocation,
      licenses: 5
    },
    modules: modules.map(x => x.name).reduce((memo, key) => ({...memo, [key]: {enabled:true, amount: 5}}), {}),
  },
  errors: {
    basic: {},
    size: {},
    modules: {},
  },
}
pureInfo.price = calculatePrice(pureInfo.data)
const initialInfo = {
  data: clean(JSON.parse(sessionStorage.getItem('info') || 'null')) || pureInfo.data,
  errors: pureInfo.errors
}
initialInfo.price = calculatePrice(initialInfo.data);

const reducer = (state, action) => {
  let newState = state;
  switch (action.type) {
    case 'errors':
      newState = {...state, errors: set(state.errors, `${action.category}.${action.field}`, action.value)}
      break;
      case 'data':
        let data = set(state.data, `${action.category}.${action.field}`, action.value)
        if(action.field === 'email'){
          data = set(state.data, `${action.category}.username`, action.value)
        }
        const price = calculatePrice(data);
        newState = {...state, data, price}
        break
      case 'clear':
        sessionStorage.removeItem('info')
        return state;
      case 'nuke':
        sessionStorage.removeItem('info')
        return pureInfo;
    default:
      throw new Error();
  }

  sessionStorage.setItem('info', JSON.stringify(newState.data))
  return newState
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialInfo);
  useDataValidation(state.data, dispatch);

  return [state, dispatch];
} 