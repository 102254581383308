import React from 'react'
import { Link } from 'react-router-dom'
import './navigation.scss'

// const links = ['/konto', '/ydelser', '/backup-moduler']
const links = ['/konto', '/backup-moduler']
export default ({activeFromZero}) => {

  return <div className="navigation container">
    {
      links.map((link, index) => {
        if(index === activeFromZero){
          return <div key={link} className="item active">{ index + 1 }</div>
        } else if(index < activeFromZero) {
          return <Link key={link} to={link}><div className="item completed">{ index + 1 }</div></Link>
        }
        
        return <div key={link} className="item">{ index + 1 }</div>
      })
    }
  </div>
}