import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6Ldp03AeAAAAANNxMU_eH4O3V4oqDhyQLVHrP6YZ"
    language="da">
    <App/>
  </GoogleReCaptchaProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
