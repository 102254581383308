import React from "react";
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import './CtaButton.scss'

export default({
  to,
  final = false,
  disabled = false,
  external = false,
  onClick = null,
  children
}) => external 
  ? <a href={to} className={classNames('cta-button', {final})}>{children}</a>
  : <Link
    onClick={onClick}
    className={classNames('cta-button', {final})}
    to={to}
    disabled={disabled}>
    {children}
  </Link>