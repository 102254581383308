import React from 'react'
import { withRouter, Redirect } from 'react-router'
import qs from 'qs'
import { useDataProvider } from '../../components/dataContext'

// [category, field]
const map = {
  email: ['basic', 'email'],
  // placement: ['size', 'location'],
  size: ['size', 'size'],
  ref: ['basic', 'ref'],
}

const Welcome = (props) => {
  const { dispatch } = useDataProvider()
  
  const data = qs.parse(props.location.search, {ignoreQueryPrefix: true});

  const keysToUse = Object.keys(data)
    .filter(key => key in map)
  
  if(data.kampagne === 'billig-1tb' || keysToUse){
    dispatch({type: 'nuke'})
  }

  if(data.kampagne === 'billig-1tb'){
    dispatch({type: 'data', category: 'size', field: 'size', value: 1024})
    dispatch({type: 'data', category: 'size', field: 'location', value: 'nl'})
    dispatch({type: 'data', category: 'size', field: 'campaign', value: 'billig-1tb'})
  }

  if(keysToUse){
    keysToUse.forEach(key => {
      const [category, field] = map[key]
      const value = data[key]
      dispatch({type: 'data', category, field, value})  
    })
  }
  

  return <Redirect to='/konto' />
}

export default withRouter(Welcome);