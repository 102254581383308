import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Header from './components/Header'
import Basic from './pages/basic'
import Size from './pages/size'
import Modules from './pages/modules'
import Welcome from './pages/welcome'
import Process from './pages/process'
import { DataProviderProvider, useDataProvider } from './components/dataContext'

const hasErrors = (errors) => Object.values(errors).some(x => x)
const BasicGuardRoute = (props) => {
  const { state: {errors} } = useDataProvider();
  const basicInvalid = hasErrors(errors.basic)

  return basicInvalid
    ? <Redirect to={'/konto'}  />
    : <Route {...props} />
}

const SizeGuardRoute = (props) => {
  const { state: {errors} } = useDataProvider();
  const basicInvalid = hasErrors(errors.basic)
  const sizeInvalid = hasErrors(errors.size)

  return basicInvalid || sizeInvalid
  ? <Redirect to={'/ydelser'}  />
  : <Route {...props} />
}

const ModulesGuardRoute = (props) => {
  const { state: {errors} } = useDataProvider();
  const basicInvalid = hasErrors(errors.basic)
  const sizeInvalid = hasErrors(errors.size)
  const modulesInvalid = hasErrors(errors.modules)

  return basicInvalid || sizeInvalid || modulesInvalid
  ? <Redirect to={'/backup-moduler'}  />
  : <Route {...props} />
}

export default () => {
  
  return (
    <Router>
      <Header />
      <DataProviderProvider>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route exact path="/konto" component={Basic} />
          <BasicGuardRoute  exact path="/ydelser" component={Size} />
          {/* <SizeGuardRoute exact path="/backup-moduler" component={Modules} /> */}
          <SizeGuardRoute exact path="/opret" component={Process} />
          <Redirect to={{search: window.location.search, pathname: '/'}}  />
        </Switch>
      </DataProviderProvider>
    </Router>
  );
}