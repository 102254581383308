import * as EmailValidator from 'email-validator'

export const hasValue = (input) => input != null && input
  .trim()
  .length > 0;

export const isEmail = (input) =>
  EmailValidator.validate(input)

export const strongPassword = (input) => 
  input.length >= 6
  && /[a-z]/.test(input)
  && /[A-Z]/.test(input)
  && /[0-9]/.test(input)
  && /[^a-zA-Z0-9]/.test(input)

export const usernameIsAvailable = async (input) => {
  const url = "https://awnogi5bz2.execute-api.eu-west-1.amazonaws.com/default/user?username=" + input
  const response = await fetch(url, {
    method: "GET",
    mode: 'cors',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Api-Key": "nNMMTDhF0j398FqkhNUn679a0EtQRfji1cYojC9N"
    }
  });
  const { isFree } = await response.json()

  return isFree;
}