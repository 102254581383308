import { useDataProvider } from '../../../components/dataContext'

export default () => {
  const { dispatch, state: { price, data: {size: {size, licenses, location, campaign} }} } = useDataProvider();

  return {
    setSize: (value) => dispatch({type: 'data', category: 'size', field: 'size', value}),
    setLicenses: (value) => dispatch({type: 'data', category: 'size', field: 'licenses', value}),
    setLocation: (value) => dispatch({type: 'data', category: 'size', field: 'location', value}),
    location,
    size,
    licenses,
    price,
    campaign,
  }
}