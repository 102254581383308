import { useEffect } from 'react'
import {hasValue, isEmail, strongPassword, usernameIsAvailable} from './validationHelpers'

const useValidateBasic = async ({name, company, email, username, password, passwordConfirmation}, dispatch) => {
  useEffect(() => {
    let value = false;
    if(name === undefined){
      value = true
    } else if(!hasValue(name)){
      value = "Feltet er obligatorisk"
    }

    dispatch(({type: 'errors', category: 'basic', field: 'name', value}))
  }, [name, dispatch])
  
  // useEffect(() => {
  //   let value = false;
  //   if(company === undefined){
  //     value = true
  //   } else if(!hasValue(company)){
  //     value = "Feltet er obligatorisk"
  //   }

  //   dispatch(({type: 'errors', category: 'basic', field: 'company', value}))
  // }, [company, dispatch])
  useEffect(() => {
    let value = false;
    if(email === undefined){
      value = true
    } else if(!hasValue(email)){
      value = "Feltet er obligatorisk"
    } else if(!isEmail(email)){
      value = 'Addressen er ikke i korrekt format'
    }

    dispatch(({type: 'errors', category: 'basic', field:'email', value}))
  }, [email, dispatch])
  useEffect(() => {
    let value = false;
    if(password === undefined){
      value = true
    } else if(!hasValue(password)){
      value = "Feltet er obligatorisk"
    } else if(!strongPassword(password)){
      value = 'Adgangskoden lever ikke op til kravene'
    }

    dispatch(({type: 'errors', category: 'basic', field:'password', value}))
  }, [password, dispatch])
  // useEffect(() => {
  //   let value = false;
  //   if(passwordConfirmation === undefined){
  //     value = true
  //   } else if(passwordConfirmation !== password){
  //     value = 'Adgangskoderne er ikke ens'
  //   }

  //   dispatch(({type: 'errors', category: 'basic', field:'passwordConfirmation', value}))
  // }, [password, dispatch, passwordConfirmation])

  // useEffect(() => {
  //   if(username === undefined){
  //     dispatch(({type: 'errors', category: 'basic', field:'username', value: true}))
  //   } else if(!hasValue(username)){
  //     dispatch(({type: 'errors', category: 'basic', field:'username', value: "Feltet er obligatorisk"}))
  //   } else if(hasValue(username)){
  //     usernameIsAvailable(username).then(isFree => {
  //       const value = !isFree && 'Brugernavnet er optaget';
  //       dispatch(({type: 'errors', category: 'basic', field: 'username', value}))
  //     })
  //   }
  // }, [username, dispatch])
}

const useValidateSize = async (size) => {
  useEffect(() => {

  })
}

const useValidateModules = (modules) => {
  return {};
}


export default ({basic, modules, size}, dispatch) => {
  useValidateBasic(basic, dispatch)
  useValidateSize(size, dispatch)
  useValidateModules(modules, dispatch)
}

