import { useState } from 'react'
import { useDataProvider } from '../../../components/dataContext'

export default () => {
  const { dispatch, state: {data: {basic: state}, errors: {basic: errors} } } = useDataProvider();
  const [pageState, setPageState] = useState(state);

  const createPart = (part) => ({
    value: pageState[part] || "",
    onChange: (e) => setPageState({...pageState, [part]: e.target.value}),
    onBlur: (e) => dispatch({type: 'data', category: 'basic', field: part, value: e.target.value})
  });

  return {
    name: createPart('name'),
    email: ({
      value: pageState.email || "",
      onChange: (e) => setPageState({...pageState, email: e.target.value}),
      onBlur: (e) => {
        dispatch({type: 'data', category: 'basic', field: "email", value: e.target.value})
        dispatch({type: 'data', category: 'basic', field: "username", value: e.target.value})
      }
    }),
    company: createPart('company'),
    username: createPart('username'),
    password: {
      value: state.password,
      onChange: (e) => dispatch({type: 'data', category: 'basic', field: 'password', value: e.target.value}),
    },
    passwordConfirmation: {
      value: state.passwordConfirmation,
      onChange: (e) => dispatch({type: 'data', category: 'basic', field: 'passwordConfirmation', value: e.target.value}),
    },
    errors
  }
}