import React from "react";
import LargeImage from '../../components/largeThanks'
import useProcess from './hooks'

import '../../components/CtaButton.scss'
import "./process.scss";

const Loading = () => {
  return <p>Vent venligst et øjeblik...</p>;
};

const Failed = () => {
  return <p>
    Der skete desværre en fejl, prøv enten igen senere eller kontakt os på:&nbsp;
    <a className='phone' href="tel:+4571990899">+45 71 99 08 99</a>
  </p>;
};

const Thanks = ({name, username, password, email}) => {
  return (
    <>
      <p>
        <b>Hej {name.split(" ")[0]}</b><br /><br />

        Din backupkonto er nu oprettet og vi er glade for at byde dig velkommen til Dansave.<br /><br />

        Kom nemt i gang med din backup ved at logge på kontrolpanelet, hvor du kan downloade backupprogrammet. Får du brug for hjælp undervejs, sidder vi klar til at give dig en hånd. Brug chatfunktionen på siden eller skriv til os på <a href="mailto:support@dansave.dk">support@dansave.dk</a><br /><br />

        Foruden de første 30 dages gratis backup, har du også muligheden for 20 % rabat eller optjene gratis ekstra backup storage. Log på kontrolpanelet herunder, og klik på “fordele og rabatter” for at læse mere.
      </p>
      <div className="columns">
        <div className="column is-offset-half is-half">
          <form action="https://www.dansavebackup.dk/login" method="POST">
            <input type="hidden" value={email} name="email" />
            <input type="hidden" value={password} name="password" />
            <input type="hidden" value="1" name="remember" />
            
            <button className={'cta-button'} style={{fontSize: '1em'}} type='submit'>Gå til dit kontrolpanel</button>
          </form>
        </div>
      </div>
    </>
  )
};

export default () => {
  const { status, name, email, password, username } = useProcess()
  // const { status, name, email, password, username } = {
  //   status: "loaded", name: "Rick Marker", email: "rick@rickmarker.dk", password: "MYPASSWORD", username: "My username" };

  const Component = {
    initial: Loading,
    loading: Loading,
    loaded: Thanks,
    failed: Failed,
  }[status]

  return <div className="section modules" style={{marginTop: '15px'}}>
    <div className="container">
      <div className="columns is-main-columns is-desktop">
        <div className="column"><LargeImage /></div>
        <div className="column">
          <h2 className="main-title">Velkommen til Dansave Backup!</h2>
          <br />
          <Component name={name} username={username} password={password} email={email} />
        </div>
      </div>
    </div>
  </div>
}