import React from "react";
import Navigation from '../../components/navigation'
import LargeImage from '../../components/largeImage'
import useSize from './hooks'
import CtaButton from '../../components/CtaButton'
import Slider from 'rc-slider';
import { sizePrice } from '../../data'
import { Redirect } from 'react-router-dom'
import 'rc-slider/assets/index.css';

import "./size.scss";

const Content = () => {
  const {
    size,
    setSize,
    licenses,
    setLicenses,
    location,
    setLocation,
    price,
    campaign,
  } = useSize()
  if(campaign === 'billig-1tb'){
    return <Redirect to="/opret" />
  }
  const sizes = Object.keys(sizePrice[location]).reduce((memo, size, i) => ({
    ...memo,
    [i]: {label: `${size} GB`, style: {height: '100px'}}
  }), {})
  
  return <div className="section size">
    <div className="container">
      <div className="columns is-main-columns is-desktop">
        <div className="column"><LargeImage /></div>
        <div className="column">
          <h2 className="main-title">Hurtig og sikker backup af dine filer</h2>
          <form>
            {/* <div className="field">
              <label className="label">Antal licenser</label>
              <div className="control">
                <div className="select">
                  <select defaultValue={licenses} onChange={e => setLicenses(e.target.value)}>
                    {
                      Array.from({length: 100}).map((_, i) => <option key={i} value={i + 1}>
                        {i === 0 ? "1 (Inklusive)" : i + 1}
                      </option>)
                    }
                  </select>
                </div>
              </div>
              <p className="help">25 kr pr md pr licens, første licens er gratis. 1 licens giver adgang til at tage backup af en server/maskine</p>
            </div> */}
            {/* <div className="datacenter">
              <label className="label">Vælg datacenter</label>
              <div className="control">
                <label className="radio">
                  <input checked={location === 'dk'} onChange={() => setLocation('dk')} type="radio" name="location" />
                  Dansave Datacenter (DK)
                </label>
                <label className="radio">
                  <input checked={location === 'nl'} onChange={() => setLocation('nl')} type="radio" name="location" />
                  Dansave Datacenter (EU)
                </label>
              </div>
            </div> */}
            <div className="field">
              <label className="label">Antal GB</label>
              <div className="slider-box">
                <div className="control">
                  <Slider
                    defaultValue={Object.keys(sizePrice[location]).indexOf(size)}
                    onChange={(index) => setSize(Object.keys(sizePrice[location])[index])}
                    min={-0.5}
                    handleStyle={{width: '30px', height: '30px', marginTop: '-13px'}}
                    dotStyle={{display: 'none'}}
                    max={Object.keys(sizes).length - 0.5}
                    step={null} marks={sizes}
                    included={false} />
                    <p className='size-price'>{sizePrice[location][size]} DKK per måned</p>
                </div>
              </div>
            </div>
            <p className="help">Ved +1000GB kontakt os direkte på <a href="tel:+4571990899">+45 71 99 08 99</a></p>
            <div className="columns bottom">
              <div className="column">
                {/* <div><span className="has-text-weight-bold">Samlet pris</span> <span>(ex moms)</span></div>
                <div className="has-text-weight-bold is-size-5">{price.toLocaleString()} DKK</div> */}
              </div>
              <div className="column">
                {/* <CtaButton to="/backup-moduler">Gå til backupmoduler</CtaButton> */}
                <CtaButton
                  onClick={() => window.gtag('event', 'conversion', { 'send_to': 'AW-951496027/hHqcCJO5oeQBENva2sUD' })}
                  to="/opret"
                >Færdiggør oprettelse</CtaButton>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
}

export default () => <>
  <Navigation activeFromZero={1} />
  <Content />
</>