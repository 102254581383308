export const pricePerLicense = 25

export const mainUrl = 'https://www.dansave.dk';

export const sizePrice = {
  nl: {
    // 50: 49,
    // 100: 79,
    250: 79,
    500: 149,
    // 750: 349,
    1000: 289,
  },
  // dk: {
  //   50: 79,
  //   100: 99,
  //   250: 199,
  //   500: 349,
  //   750: 499,
  //   1000: 599,
  // },
}

export const modules = [
  {
    name: "exchange",
    title: "MS Exchange Individual Backup Modul",
    helperText: "5 kr. pr. mailbox der skal tages backup af",
    inputLabel: "Mailbokse",
    number: true,
    price: 5
  }, {
    name: "synology",
    title: "Synology NAS Backup Modul",
    helperText: "20 kr. for aktivering af Synology modul",
    number: false,
    price: 20,
  }, {
    name: "qnap",
    title: "QNAP NAS Backup Modul",
    helperText: "20 kr. for aktivering af Synology NAS Backup modul",
    number: false,
    price: 20,
  }, {
    name: "vmware",
    title: "VMWare Backup Modul",
    helperText: "5 kr. pr. VM",
    inputLabel: "VM",
    number: true,
    price: 5,
  }, {
    name: "hyper-v",
    title: "Hyper-V Backup Modul",
    helperText: "5 kr. pr. VM",
    inputLabel: "VM",
    number: true,
    price: 5,
  }, {
    name: "granular",
    title: "Granular Restore / Open Direct Modul",
    inputLabel: "Backupsæt",
    helperText: "5 kr. pr. backupsæt",
    number: true,
    price: 5,
  }, {
    name: "365",
    title: "Office 365 Exchange Online Individual Mailbox Backup Modul",
    helperText: "5 kr. pr. Office 365 Mailbox",
    inputLabel: "Mailbokse",
    number: true,
    price: 5,
  }, {
    name: "monitoring",
    title: "Overvågning af backup",
    helperText: "50 kr. pr. måned",
    inputLabel: "Overvågning",
    number: false,
    price: 50
  }
]

export const calculatePrice = ({size: {licenses, size, location}, modules: moduleSettings}) => {
  return Object.keys(moduleSettings)
    .filter(moduleName => moduleSettings[moduleName].enabled)
    .reduce((totalPrice, moduleName) => {
      const moduleInfo = modules.find(x => x.name === moduleName)
      const modulePrice = moduleInfo.price;
      const additionalPrice = moduleInfo.number
        ? modulePrice * moduleSettings[moduleName].amount
        : modulePrice

      return totalPrice + additionalPrice
    }, (licenses - 1) * pricePerLicense + sizePrice[location][size])
  }