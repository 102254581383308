import React, { createContext, useContext } from 'react'
import useContextHook from './hooks'

const DataProviderContext = createContext({});

export const DataProviderProvider = ({children}) => {
  const [state, dispatch] = useContextHook()

  return (
    <DataProviderContext.Provider value={{
        state,
        dispatch
      }}
    >
        { children }
    </DataProviderContext.Provider>
  )
}

export const useDataProvider = () => useContext(DataProviderContext)