import {useEffect, useState} from 'react'
import { useDataProvider } from '../../../components/dataContext'
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const doAsyncApiCall = async (data, setStatus, clear, ip, executeRecaptcha) => {
  if (!executeRecaptcha) {
    throw new Error('Captcha not ready');
  }
  const captcha = await executeRecaptcha();
  // eslint-disable-next-line
  const url = "https://awnogi5bz2.execute-api.eu-west-1.amazonaws.com/default/user"
  try {
    setStatus('loading')
    const response = await fetch(url, {
      method: "POST",
      mode: 'cors',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Api-Key": "nNMMTDhF0j398FqkhNUn679a0EtQRfji1cYojC9N"
      },
      body: JSON.stringify({...data, ip, captcha})
    });

    if (response.status === 400) {
      throw new Error('Something went wrong');
    }
    setStatus('loaded')
    clear();
  } catch (e) {
    console.log(e);
    setStatus('failed')
  }
}

const getIp = async () => {
  try {
    const ipResponse = await fetch("https://api.ipify.org?format=json");
    const ipJson = await ipResponse.json();
    return ipJson.ip;
  } catch(e){
      return null;
  }
}

export default () => {
  const { dispatch, state: { data } } = useDataProvider();
  const [status, setStatus] = useState('initial')
  const { executeRecaptcha } = useGoogleReCaptcha();
  
  const clear = () => dispatch({type: 'clear'})
  
  useEffect(() => {
    getIp()
      .then(ip => doAsyncApiCall(data, setStatus, clear, ip, executeRecaptcha))
    // eslint-disable-next-line
  },[])
  return {
    status,
    name: data.basic.name,
    email: data.basic.email,
    password: data.basic.password,
    username: data.basic.username,
  }
}